<template>
	<ion-page>
		<ion-header :translucent="true">
			<ion-toolbar color="ari" class="ion-text-center">
				<ion-title>Konsultasi Selesai</ion-title>
			</ion-toolbar>
			<ion-progress-bar
				v-if="store.state.isLoading"
				type="indeterminate"
			></ion-progress-bar>
		</ion-header>

		<ion-content :fullscreen="true" slot="fixed">
			<ion-list class="conversation" v-if="store.state.ticket.complete != null">
				<!-- <pre>{{ store.state.ticket.complete }}</pre> -->
				<ion-item
					color="light"
					v-for="(d, i) in store.state.ticket.complete"
					:key="i"
					:router-link="'/ticket-information-detail/' + d.TICKET_NO"
				>
					<!-- <ion-icon
						:icon="checkmarkCircleOutline"
						class="personIcon"
					></ion-icon> -->
					<!-- new code -->
					<img src="@/assets/relidoc/selesai.svg" alt="" class="icon-width-2" />
					<ion-label class="list-chat">
						<span class="blue-ari">{{ formatDate(d.TICKET_START_DATE) }}</span
						><br />
						<span>Nomor Tiket : {{ d.TICKET_NO }}</span
						><br />
						<span
							>Pasien : <b>{{ d.MEMBER_NAME }}</b></span
						><br />
						<span>Indikasi : {{ d.TICKET_INDICATION }}</span
						><br />
						<span>Status : {{ d.TICKET_STATUS_NAME.toLowerCase() }}</span>
					</ion-label>
				</ion-item>
			</ion-list>
			<div v-if="store.state.ticket.complete == null">
				<!-- <div>
					<ion-icon :icon="alertCircleOutline"></ion-icon><br />
					<span>Belum ada konsultasi selesai.</span>
				</div> -->
				<div class="flex">
					<div class="no-content">
						<!-- <ion-icon :icon="alertCircleOutline"></ion-icon><br />
						<span>Belum ada konsultasi selesai.</span> -->

						<!-- newcode -->
						<div class="icon-wrap">
							<img
								src="@/assets/relidoc/informasi.svg"
								alt=""
								class="icon-width"
							/>
							<span>Tidak ada permintaan konsultasi</span>
						</div>
					</div>
				</div>
			</div>
			<!-- <pre>
				{{ store.state.ticket }}
			</pre
			> -->
		</ion-content>
		<bottom-tab-bar></bottom-tab-bar>
	</ion-page>
</template>

<script>
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonList,
	IonItem,
	// IonIcon,
	IonLabel,
	IonProgressBar,
	onIonViewWillEnter,
} from "@ionic/vue";
import { formatDate } from "@/store/methods/utils/utils";
import { alertCircleOutline, checkmarkCircleOutline } from "ionicons/icons";
import { inject } from "vue";
import BottomTabBar from "@/components/BottomTabBar.vue";

export default {
	name: "Conversation",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		IonTitle,
		IonToolbar,
		IonList,
		IonItem,
		// IonIcon,
		IonLabel,
		IonProgressBar,
		BottomTabBar,
	},
	setup() {
		const store = inject("store");
		onIonViewWillEnter(() => {
			store.ticket.listTicketComplete();
		});
		return {
			store,
			checkmarkCircleOutline,
			alertCircleOutline,
			formatDate,
		};
	},
};
</script>

<style scoped>
.icon-wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.icon-width {
	width: 44px;
	margin-bottom: 10px;
}
.icon-width-2 {
	width: 40px;
	margin-right: 15px;
	margin-left: 5px;
}
ion-item {
	cursor: pointer;
}
.personIcon {
	color: var(--ion-color-ari);
	margin-right: 5px;
	margin-left: 0px;
	font-size: 50px;
}
.list-chat {
	color: #464947;
	padding: 0;
}
.conversation {
	padding: 0;
}
.flex {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 79vh;
	overflow: auto;
}
.no-content {
	justify-content: center;
	text-align: center;
	color: rgb(179, 179, 179);
}
.no-content > ion-icon {
	font-size: 50px;
	color: rgb(179, 179, 179);
}
</style>
